<template>
<div v-if="$store.state.pricing.rule.definition && $store.state.pricing.defs">
  <q-toolbar  class="bg-primary shadow2 text-white" style="max-width: 320px; overflow: hidden;">
    <q-btn flat round dense icon="cancel" @click="goback"/>
    <q-toolbar-title >
      {{$t('Implementation Strategies')}}
    </q-toolbar-title>
  </q-toolbar>
  <div class="drawer-container">
    <q-list no-border dense>
    <q-expansion-item header-class="accordeon-params" :label="s.for" v-for="(s, index) in $store.state.pricing.rule.definition.implstrategy" :key="index">
      <div class="lg-common-settings attrlist">
        <q-select class="col-12" :disable="readonly" filled square dense :label="$t('Implementation type')" emit-value map-options
          :value="$store.state.pricing.rule.definition.implstrategy[index].type" :options="strategytypes" @input="(v) => change(index, 'type', v)">
        </q-select>

        <q-select v-if="$store.state.pricing.rule.definition.implstrategy[index].type === 'progressive'" class="col-12" :disable="readonly" filled square dense :label="$t('Target Attribute')" emit-value map-options
          :value="$store.state.pricing.rule.definition.implstrategy[index].target" :options="targetattributes" @input="(v) => change(index, 'target', v)">
        </q-select>
        <q-input v-if="$store.state.pricing.rule.definition.implstrategy[index].type === 'progressive'" class="col-12" :disable="readonly" filled square dense :label="$t('Attribute markup')"
          type="number" :value="$store.state.pricing.rule.definition.implstrategy[index].markup" @input="(v) => change(index, 'markup', v)"/>
        <q-select v-if="['progressive', 'none'].includes($store.state.pricing.rule.definition.implstrategy[index].type)" class="col-12" :disable="readonly || isMulti2()" filled square dense :label="$t('Reference Price Attribute')" emit-value map-options
          :value="$store.state.pricing.rule.definition.implstrategy[index].reference.attr" :options="attributes" @input="change(index, 'reference.attr', v)">
        </q-select>
        <date-field v-if="['progressive', 'none'].includes($store.state.pricing.rule.definition.implstrategy[index].type)" class="col-12" :disabled="readonly || isMulti2()" filled square dense :label="$t('Reference Date')"
          :value="$store.state.pricing.rule.definition.implstrategy[index].reference.date" name="date" @change="change(index, 'reference.date', v)" :locale="$store.state.auth.locale"></date-field>
        <div class="col-12 threshold">
          <q-input type="number" v-if="$store.state.pricing.rule.definition.implstrategy[index].type === 'progressive'" :disable="readonly" filled square dense :label="$t('Default Implementation threshold')"
            :value="$store.state.pricing.rule.definition.implstrategy[index].threshold" @input="(v) => change(index, 'threshold', v)" />
          <q-btn v-if="$store.state.pricing.rule.definition.implstrategy[index].type === 'progressive'"  :loading="showEdit[index]" dense color="primary" icon="edit" @click="() => openModale(index)" :label="$t('Edit')" >
            <template v-slot:loading>
              <q-spinner-gears />
            </template>
          </q-btn>
        </div>
        <q-input v-if="$store.state.pricing.rule.definition.implstrategy[index].type === 'progressive'" class="col-12" :disable="readonly" filled square dense :label="$t('Rolling period in months')"
          :value="$store.state.pricing.rule.definition.implstrategy[index].period" @input="(v) => change(index, 'period', v)" />

        <modale  v-if="$store.state.pricing.rule.definition.implstrategy[index].type === 'progressive'"  :show="showEdit[index]"  :label="'Cost plus edit'" :value="$store.state.pricing.rule.definition.implstrategy[index].thresholdtree"
          @closemodale="() => closeModale(index)" @input="(v) => costPlusHasChange(index, v)" style="margin-top: 10px;">
        </modale>
      </div>
    </q-expansion-item>
    </q-list>
  </div>
</div>
</template>
<script>
import pricingUtilities from '@cabinetmariel/pricing-utilities';
import { localization } from '../../lib/mixins';
import { rulemixins } from './mixins';
import DateField from '../../components/attributesDisplay/DateField.vue';
import modale from './costPlus/CostPlusEdit.vue';

const { utils } = pricingUtilities;
const { isFamilyBased } = utils;

export default {
  components: { DateField, modale },
  mixins: [localization, rulemixins],
  data() {
    return {
      showEdit: [false, false],
      defaultattributes: [
        'rawproposedprice',
      ],
      multipivot2attributes: [
        'sfrecommended',
        'lfrecommended',
        'lowerpricerange_upperbound',
        'lowerpricerange_lowerbound',
        'upperpricerange_upperbound',
        'upperpricerange_lowerbound',
      ],
      strategytypes: [
        { value: 'full', label: this.$t('Full') },
        { value: 'none', label: this.$t('None') },
        { value: 'progressive', label: this.$t('Progressive') },
      ],
    };
  },
  mounted() {
    // console.log(this.$store.state.pricing.rule.definition.implstrategy);
  },
  methods: {
    openModale(index) {
      const tmp = [...this.showEdit];
      tmp[index] = true;
      this.showEdit = tmp;
    },
    closeModale(index) {
      const tmp = [...this.showEdit];
      tmp[index] = false;
      this.showEdit = tmp;
    },
    costPlusHasChange(index, def) {
      console.log('costPlusHasChange', def);

      this.$store.commit('pricing/setImplementationRate', { index, def });
      this.$q.notify({ type: 'positive', message: 'Your modifications have been saved', icon: 'done' });
      this.closeModale(index);
    },
    change(index, key, value) {
      this.$store.commit('pricing/setImplementationStrategy', { index, key, value });
    },
    isMulti2() {
      if (!(['vp', 'cpt'].includes(this.$store.state.pricing.rule.method) && this.$store.state.pricing.rule.definition.pivottype === 'multi2')) {
        return false;
      }
      return true;
    },
  },
  computed: {
    readonly() {
      return !!((this.$store.state.pricing.single && isFamilyBased(this.$store.state.pricing.rule)) || this.$store.state.pricing.rule.link);
    },
    targetattributes() {
      const attrs = [...this.defaultattributes, ...(this.isMulti2() ? this.multipivot2attributes : [])];
      return Object.values(this.$store.state?.pricing?.defs?.defs2 || []).filter((a) => attrs.includes(a.name))
        .map((a) => ({ value: a.name, label: this.localized(a.label) }))
        .sort((a, b) => a.label.localeCompare(b.label));
    },
    attributes() {
      if (this.$store.state?.pricing?.defs?.defs2) {
        return Object.values(this.$store.state.pricing.defs.defs2).map((attr) => ({ value: attr.name, label: this.localized(attr.label) }))
          .sort((a, b) => a.label.localeCompare(b.label));
      }
      return [];
    },
  },
};
</script>
<style lang="stylus" scoped>
.accordeon-params {
    font-weight: bold;
    border-bottom: 1px solid #000;
}
.lg-common-settings {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .threshold {
    display: flex;
    gap: 4px;
    > :first-child {
      flex: 1;
    }
  }
}
</style>
