import * as Sentry from '@sentry/vue';
import { ExtraErrorData } from '@sentry/integrations';

export default ({ store, router, Vue }) => {
  const [host, environment] = process.env.TARGET.split(':');

  if (host.startsWith('localhost') || host.startsWith('192.')) {
    // Omit logging events locally.
    return;
  }
  /* Sentry.replayIntegration({
    maskAllText: false,
    blockAllMedia: false,
  }); */
  Sentry.init({
    Vue,
    environment,
    // This should be changed per project/environment
    // TODO: Consider extracting it within env variable
    dsn: process.env.SENTRY_DSN,
    release: window.apprelease,
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
      new ExtraErrorData({ depth: 10 }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysSessionSampleRate: 0.2,
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    initialScope: (scope) => {
      scope.setTags({
        host,
        authenticated_user: store?.state?.auth?.user,
      });
      return scope;
    },
  });
};

const logError = (err) => {
  const [, environment] = process.env.TARGET.split(':');

  if (environment !== 'development') {
    Sentry.captureException(err);
  } else {
    console.error(err);
  }
};

export { logError };
