import { render, staticRenderFns } from "./Pricing.vue?vue&type=template&id=dd3cea54&"
import script from "./Pricing.vue?vue&type=script&lang=js&"
export * from "./Pricing.vue?vue&type=script&lang=js&"
import style0 from "./Pricing.vue?vue&type=style&index=0&lang=stylus&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QBanner from 'quasar/src/components/banner/QBanner.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSplitter from 'quasar/src/components/splitter/QSplitter.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QPage from 'quasar/src/components/page/QPage.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QBtnGroup from 'quasar/src/components/btn-group/QBtnGroup.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSpinnerHourglass from 'quasar/src/components/spinner/QSpinnerHourglass.js';
import QPagination from 'quasar/src/components/pagination/QPagination.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QField from 'quasar/src/components/field/QField.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QBanner,QIcon,QSplitter,QPageContainer,QPage,QBtn,QTooltip,QBtnGroup,QInput,QSpinnerHourglass,QPagination,QSelect,QSeparator,QField});
