<template>
  <div>
    <q-toolbar  class="bg-primary shadow2 text-white">
      <q-btn flat round dense icon="cancel" @click="goback"/>
      <q-toolbar-title>
        {{$t('Customer supervision')}}
      </q-toolbar-title>
    </q-toolbar>
    <div  class="drawer-container">
      <div class="drawer-section">
        <p class="drawer-section-title">{{$t('Highlighted parts :')}}</p>
        <q-select filled square dense :label="$t('1st part')" v-model="highlightedParts[0]" :options="availlableParts" option-value="id" option-label="name" />
        <q-select filled square dense :label="$t('2nd part')" v-model="highlightedParts[1]" :options="availlableParts" option-value="id" option-label="name" />
        <q-select filled square dense :label="$t('3rd part')" v-model="highlightedParts[2]" :options="availlableParts" option-value="id" option-label="name" />
        <p class="drawer-section-title">{{$t('Review status :')}}</p>
        <q-select filled square dense :label="$t('Status')" v-model="status" :options="statuses"/>
        <q-checkbox class="checkbox" v-model="saveComputedAttributes" label="Save the computed attributes (you will not have to wait for the nightly batch, but it takes a little time, wait for the confirmation that all are saved before leaving the page)" />
        <q-btn color="secondary" icon="save" @click="save()">{{$t('Save')}}</q-btn>
        <q-btn color="secondary" icon="link" @click="generateLink()">{{$t('Copy link')}}</q-btn>
      </div>
    </div>
  </div>
</template>
<script>
import localization from '../../lib/mixins'; // eslint-disable-line
import { rulemixins } from './mixins';
import { store } from '../../lib/nrstore';

export default {
  mixins: [localization, rulemixins],
  data() {
    return {
      highlightedParts: [null, null, null],
      availlableParts: [],
      status: null,
      statuses: [
        { label: this.$t('Not Ready'), value: 0 },
        { label: this.$t('Work in progress'), value: 1 },
        { label: this.$t('Family rule Validated'), value: 2 },
      ],
      saveComputedAttributes: false,
    };
  },
  mounted() {
    this.status = this.statuses.find((s) => s.value === this.$store.state.pricing.rule.review) || this.statuses[0];
    this.availlableParts = store.parts.map((p) => ({ id: p.id.v, name: p.name.v }));
    // console.log('STORE', this.availlableParts);
    // console.log('STORE SAVED', this.$store.state.pricing.rule.definition.highlightedParts);
    if (!this.$store.state.pricing.rule.definition) this.$store.state.pricing.rule.definition = {};
    if (this.$store.state.pricing.rule.definition.highlightedParts) {
      this.highlightedParts = this.$store.state.pricing.rule.definition.highlightedParts;
    }
  },
  methods: {
    empty(id) {
      this.highlightedParts[id] = '';
    },
    save() {
      // console.log('SAVE', this.highlightedParts);
      this.$store.commit('pricing/setHighlightedParts', {
        status: this.status.value,
        highlightedParts: this.highlightedParts,
      });
      this.$store.dispatch('pricing/save');
      if (this.saveComputedAttributes) {
        this.$store.dispatch('pricing/storecomputed');
      }
    },
    generateLink() {
      const customerAppUrl = this.$store.state.general.settings.customerappurl;
      const familyId = this.$store.state.pricing.rule.family;
      const ruleId = this.$store.state.pricing.rule.id;
      if (!customerAppUrl) {
        this.$q.notify({
          message: this.$t('Customer app url is not defined'),
          color: 'negative',
          icon: 'error',
        });
        return;
      }
      if (!familyId) {
        this.$q.notify({
          message: this.$t('Family id is not defined'),
          color: 'negative',
          icon: 'error',
        });
        return;
      }
      if (!ruleId) {
        this.$q.notify({
          message: this.$t('Rule id is not defined'),
          color: 'negative',
          icon: 'error',
        });
        return;
      }
      // generate link
      const link = `${customerAppUrl}/key-figure?familyId=${familyId}&ruleId=${ruleId}`;
      // copy to clipboard
      const el = document.createElement('textarea');
      el.value = link;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.$q.notify({
        message: this.$t('Link copied to clipboard'),
        color: 'positive',
        icon: 'done',
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.drawer-container {
  padding: 1rem;
  .drawer-section {
    display: flex;
    flex-direction:column;
    align-items: stretch;
    gap: 1rem;
    .drawer-section-title{
      margin: 0;
      font-size: 17px; font-weight: 500;
    }
  }
}

.checkbox {
  font-size: 12px;
}
</style>
